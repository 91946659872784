

















import { Component, Vue } from "vue-property-decorator";

@Component
export default class PreviewLists extends Vue {
  // exampleData = new Array<Array<string>>(
  //   ["eins", "zwei", "drei"],
  //   ["1eins", "2zwei", "3drei"]
  // );
  exampleData = [
    ["Stewart", "Edith", "edith.stewart@example.org", "User"],
    ["Harvey", "Leah", "leah.harvey@example.org", "Admin"],
    ["Hardy", "John", "john.hardy@example.org", "User"],
    ["Douglas", "Richard", "richard.douglas@example.org", "User"],
    ["Friend", "Helen", "helen.friend@example.org", "User"],
  ];
  test = [{ name: "dog", firstname: "the" }];

  mounted() {
    console.log(this.exampleData);
  }
}
